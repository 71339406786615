/* CSS for the spinner component */
.spinner {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0 auto;
  }
  
  .spinner-inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #007bff;
    border-top: 5px solid transparent;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  