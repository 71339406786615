:root {
    --bs-app-aside-width: 320px;
    --bs-app-aside-width-actual: 320px;
    --bs-app-aside-gap-start: 0px;
    --bs-app-aside-gap-end: 30px;
    --bs-app-aside-gap-top: 30px;
    --bs-app-aside-gap-bottom: 30px;
}

.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-border-radius: 0.625rem;
    --bs-card-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
    --bs-card-inner-border-radius: calc(0.625rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    color: var(--bs-body-color);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    box-shadow: var(--bs-card-box-shadow);
}

body {
    margin: 0;
    font-family: 'Basier Circle', sans-serif;
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}




.py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.ml-auto {
    margin-left: auto;
  }

/* Style for card items (table cells) */
.card-item {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
}

.col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}

.app-main {
    display: flex;
}
@media (min-width: 992px) {
    .app-main {
        transition: margin 0.3s ease;
    }
}
@media (max-width: 991.98px) {
    .app-main {
        padding-left: 0;
        padding-right: 0;
    }
}

.link-container {
    display: flex; /* Display the links in a row */
    justify-content: space-between; /* Distribute space between the links */
    margin-bottom: 10px; /* Add some spacing at the bottom */
  }
  
  /* Style for the links */
  .link-container a {
    padding: 10px; /* Add padding around each link */
    color: #0074E1; /* Text color */
    text-decoration: none; /* Remove underline from links */
    border-radius: 5px; /* Rounded corners for links */
    font-weight: bold; /* Make the text bold */
  }

  .mobileformgroup{
    display: none !important;
  }

@media (max-width: 991.98px) {
    .link-container {
       display:none
    }
    .mobileformgroup{
        display: block !important;
      }
}

.centered-container {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 100vh; /* Set the container's height to the full viewport height */
  }

.form-group {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  
  
  label {
    margin-right: 10px; /* Adjust the spacing as needed */
  }
  
  select {
    border: none;
    background: transparent;
    /* Additional styling as needed */
  }
  

  .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.flex-md-stack {
    justify-content: space-between;
    align-items: center;
}

  .app-footer {
    transition: left 0.3s ease, right 0.3s ease;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-top: var(--bs-app-footer-border-top);
}

.hidden{
    display: none;
}

.nothidden{
    display:block
}
  

  .app-page {
    display: flex;
}

.list-icon, .list-icon i {
    cursor: pointer;
}

  @media (min-width: 992px) {
    .app-container {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .app-container-fit-desktop {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
@media (max-width: 991.98px) {
    .app-container {
        max-width: none;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .app-container-fit-mobile {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}





