.myheader {
    display: flex;
    justify-content: space-between; /* To push the logo and icon to the edges */
    align-items: center; /* To vertically center the text */
    padding: 10px; /* Add padding for spacing */
  
    /* Add any other header styles you need */
  }
  
  .centered-text {
    text-align: center;
    flex: 1; /* Take up available space in the middle */
  }
  
  .centered-text a {
    text-decoration: none; /* Remove underlines from the link */
    color: #333; /* Set the text color */
    font-size: 13px; /* Adjust the font size as needed */
  
    /* Add any other text styles you need */
  }
  