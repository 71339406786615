/* RoundedCard.css */

/* Apply these styles to your CSS file */

.rounded-card {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #D9DCE1; /* Change the background color as needed */
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  color: #fff; /* Text color */
  font-size: 20px; /* Adjust the font size as needed */
  margin-right: 10px; /* Spacing between the card and name */
}

span {
  font-size: 17px; /* Font size for the name */
  color: #949EA8;

}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

  
  .initials {
    text-align: center;
    vertical-align: middle;
    color: #949EA8;
    line-height: 60px; /* Match the card's height for vertical alignment */
  }
