.App {
    text-align: center;
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-top: 100px;
  }

  body {
    font-family: 'Basier Circle', sans-serif;
    
  }

  .eventheader{
    font-size: 20px !important;
  }

  .close-icon::before {
    content: '\f00d'; /* FontAwesome "times" icon */
    font-family: 'Font Awesome 5 Free'; /* Font family for FontAwesome icons */
    font-weight: 900; /* Font weight for the icon */
  }

  .header{
    font-family: Basier Circle;
    font-size: 28px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: center;
  }

  .subtitle{
    font-family: Basier Circle;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;

  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.775rem 1rem;
    font-size: 1.1rem;
    border: none;
    outline: none;
    font-weight: 500;
    line-height: 1.5;
    color: var(--bs-gray-700);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border-bottom: 1px solid var(--bs-gray-300);
    box-shadow: false;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-gray-700);
  background-color: var(--bs-body-bg);
  border-color: #dae1e7;
  outline: 0;
  box-shadow: false, 0 0 0 0.25rem rgba(62, 151, 255, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.logo {
  margin-left: 20px;
  margin-top: 20px;
}
  
  .cancel-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .form-container {
    text-align: center;
    /* padding: 20px; */
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"] {
    display: block;
    width: 100%;
    padding: 0.775rem 1rem;
    font-size: 1.1rem;
    border: none;
    outline: none;
    font-weight: 500;
    line-height: 1.5;
    color: var(--bs-gray-700);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border-bottom: 1px solid var(--bs-gray-300);
    box-shadow: false;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  button[type="submit"] {
    background-color: #77E3EF;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #77E3EF;
  }


  .error-mesg{
    color: #2D0DEF !important;
    float: left !important;
    display: block !important;
    /* margin-left: -250px; */
    margin-top: -20px;
  }

  .text-muted{
    color: #949EA8 !important;
  }


.blue-border {
    border-bottom: 2px solid blue; /* Blue border style */
  }

  .error-msg{
    color: #2D0DEF !important;
    float: left !important;
    display: block !important;
    margin-left: -250px;
    margin-top: -20px;
  }

  .hideerror-msg{
    display: none !important;
  }
  