@charset "UTF-8"; 
:root,

*,
::after,
::before {
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: 'Hind', sans-serif;
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: var(--bs-heading-color);
}
.h1,
h1 {
    font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
    .h1,
    h1 {
        font-size: 1.75rem;
    }
}
.h2,
h2 {
    font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
    .h2,
    h2 {
        font-size: 1.5rem;
    }
}
.h3,
h3 {
    font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
    .h3,
    h3 {
        font-size: 1.35rem;
    }
}
.h4,
h4 {
    font-size: 1.25rem;
}
.h5,
h5 {
    font-size: 1.15rem;
}
.h6,
h6 {
    font-size: 1.075rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}


.small,
small {
    font-size: 0.875em;
}
.mark,
mark {
    padding: 0.1875em;
    background-color: var(--bs-highlight-bg);
}
sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
a {
    color: black;
    text-decoration: none;
}
a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
    text-decoration: none;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

button {
    border-radius: 0;
}
button:focus:not(:focus-visible) {
    outline: 0;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
textarea {
    resize: vertical;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
}
@media (min-width: 1200px) {
    legend {
        font-size: 1.5rem;
    }
}
legend + * {
    clear: left;
}


.hidden{
    display: none;
}
.lead {
    font-size: 1.25rem;
    font-weight: 300;
}
.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 700;
    line-height: 1.2;
}

.form-label {
    margin-bottom: 0.5rem;
    font-size: 1.05rem;
    font-weight: 500;
    color: var(--bs-gray-800);
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.775rem 1rem;
    font-size: 1.1rem;
    border: none;
    outline: none;
    font-weight: 500;
    line-height: 1.5;
    color: var(--bs-gray-700);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border-bottom: 1px solid var(--bs-gray-300);
    box-shadow: false;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sidelayout-wrap{
    width: 480px;
    height: 100vh; 
    background-image: url(media/1.png);
    background-position: center;
}

@media (max-width: 976px) {
    .sidelayout-wrap{
        width:580px; 
        display: none;
        height: 100vh; 
        background-image: url(media/1.png); 
        background-position: bottom;
    }
}

.icon-container {
    position: absolute;
    top: 10px;
    right: 20px;
    transition: opacity 0.3s ease; /* Add a transition for smooth fade-in/fade-out */
    opacity: 1; /* Initially, the container is visible */
  }
  
  .icon-container.hidden {
    opacity: 0; /* When the "hidden" class is added, it will disappear */
  }
  
  /* Style for the name */
  .name {
    display: inline-block; /* Display name inline with the image */
    margin-left: 10px; /* Add some space between the image and the name */
    vertical-align: middle; /* Vertically align the name with the image */
    font-size: 14px; /* Adjust the font size for the name */
    color: #333; /* Text color for the name */
  }

.sidelayout-logo{
    margin-top: 20px;
    margin-left: 20px;
}

.lowerText{
    text-align:center; 
    color: white;
     margin-top: 550px; 
     font-family: 'Basier Circle', sans-serif;
     font-size: 40px; 
    line-height: 36.8px;
}

@media (max-width: 976px) {
    .lowerText{
        text-align:center; 
        color: white;
         margin-top: 377px; 
         font-family: 'Basier Circle', sans-serif; 
         font-size: 40px; 
        line-height: 36.8px;
    }
}



.signupText{
    color: black !important;
    position: absolute; 
    top: 0; 
    right: 0; 
    padding: 20px;
}

.signupLink{
    color: black;
}

@media (max-width: 976px) {
    .signupText{
        color: white !important;
        position: absolute; 
        display: none;
        top: 0; 
        right: 0; 
        padding: 20px;
    }
}


@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}
.form-control[type="file"] {
    overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
}
.form-control:focus {
    color: var(--bs-gray-700);
    background-color: var(--bs-body-bg);
    border-color: #dae1e7;
    outline: 0;
    box-shadow: false, 0 0 0 0.25rem rgba(62, 151, 255, 0.25);
}
.form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.5em;
    margin: 0;
}
.form-control::-webkit-datetime-edit {
    display: block;
    padding: 0;
}
.form-control::placeholder {
    color: var(--bs-gray-500);
    opacity: 1;
}
.form-control:disabled {
    color: var(--bs-gray-500);
    background-color: var(--bs-gray-200);
    border-color: var(--bs-gray-300);
    opacity: 1;
}
.form-control::file-selector-button {
    padding: 0.775rem 1rem;
    margin: -0.775rem -1rem;
    margin-inline-end: 1rem;
    color: var(--bs-gray-700);
    background-color: var(--bs-gray-100);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-control::file-selector-button {
        transition: none;
    }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: shade-color(var(--bs-gray-100), 5%);
}
.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.775rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--bs-gray-700);
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}
.form-control-plaintext:focus {
    outline: 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
}
.form-control-sm {
    min-height: calc(1.5em + 1.1rem + 2px);
    padding: 0.55rem 0.75rem;
    font-size: 0.95rem;
    border-radius: 0.425rem;
}
.form-control-sm::file-selector-button {
    padding: 0.55rem 0.75rem;
    margin: -0.55rem -0.75rem;
    margin-inline-end: 0.75rem;
}
.form-control-lg {
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: 0.625rem;
}
.form-control-lg::file-selector-button {
    padding: 0.825rem 1.5rem;
    margin: -0.825rem -1.5rem;
    margin-inline-end: 1.5rem;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    
}
.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-floating:focus-within,
.input-group > .form-select:focus {
    z-index: 5;
}
.input-group .btn {
    position: relative;
    z-index: 2;
}
.input-group .btn:focus {
    z-index: 5;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.775rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: var(--bs-gray-700);
    text-align: center;
    white-space: nowrap;
    background-color: transparent !important;
    border-bottom: 1px solid var(--bs-gray-300);
    /* border-radius: 0.475rem; */
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
    padding: 0.825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: 0.625rem;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
    padding: 0.55rem 0.75rem;
    font-size: 0.95rem;
    border-radius: 0.425rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
    padding-right: 4rem;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select,
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #fcfcfc;
    background-clip: padding-box;
    border-bottom: 1px solid var(--bs-gray-300);
    box-shadow: false;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select,
.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: calc(1px * -1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #fcfcfc !important;
    border-bottom: 1px solid var(--bs-gray-300);
    border-radius: none !important;

}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: var(--bs-form-invalid-color);
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color);
}
.form-check-inline .form-check-input ~ .invalid-feedback {
    margin-left: 0.5em;
}
.input-group > .form-control:not(:focus).is-invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-control:not(:focus):invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.was-validated .input-group > .form-select:not(:focus):invalid {
    z-index: 4;
}
.btn {
    --bs-btn-padding-x: 1.5rem;
    --bs-btn-padding-y: 0.775rem;
    --bs-btn-font-size: 1.1rem;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.475rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    box-shadow: var(--bs-btn-box-shadow);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
    --bs-btn-color: #000000;
    --bs-btn-bg: #009ef7;
    --bs-btn-border-color: #009ef7;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #64c4ce;
    --bs-btn-hover-border-color: #64c4ce;
    --bs-btn-focus-shadow-rgb: 0, 134, 210;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #33b1f9;
    --bs-btn-active-border-color: #64c4ce;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #009ef7;
    --bs-btn-disabled-border-color: #009ef7;
  }

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check) + .btn:active:focus-visible {
    box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
    box-shadow: none;
}
.btn-light {
    --bs-btn-color: #000000;
    --bs-btn-bg: #f9f9f9;
    --bs-btn-border-color: #f9f9f9;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #d4d4d4;
    --bs-btn-hover-border-color: #c7c7c7;
    --bs-btn-focus-shadow-rgb: 212, 212, 212;
    --bs-btn-active-color: #000000;
    --bs-btn-active-bg: #c7c7c7;
    --bs-btn-active-border-color: #bbbbbb;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000000;
    --bs-btn-disabled-bg: #f9f9f9;
    --bs-btn-disabled-border-color: #f9f9f9;
}

[data-bs-theme="dark"] .navbar-toggler-icon {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-border-radius: 0.625rem;
    --bs-card-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
    --bs-card-inner-border-radius: calc(0.625rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    color: var(--bs-body-color);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    box-shadow: var(--bs-card-box-shadow);
}

/* Style for card items (table cells) */
.card-item {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }



.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}
.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height);
}
.progress-stacked > .progress {
    overflow: visible;
}
.progress-stacked > .progress > .progress-bar {
    width: 100%;
}
.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}
.list-group {
    --bs-list-group-color: var(--bs-gray-900);
    --bs-list-group-bg: #ffffff;
    --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
    --bs-list-group-border-width: var(--bs-border-width);
    --bs-list-group-border-radius: var(--bs-border-radius);
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 0.5rem;
    --bs-list-group-action-color: var(--bs-gray-700);
    --bs-list-group-action-hover-color: var(--bs-gray-700);
    --bs-list-group-action-hover-bg: var(--bs-gray-100);
    --bs-list-group-action-active-color: var(--bs-body-color);
    --bs-list-group-action-active-bg: var(--bs-gray-200);
    --bs-list-group-disabled-color: var(--bs-gray-600);
    --bs-list-group-disabled-bg: #ffffff;
    --bs-list-group-active-color: var(--bs-component-active-color);
    --bs-list-group-active-bg: var(--bs-component-active-bg);
    --bs-list-group-active-border-color: var(--bs-component-active-bg);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--bs-list-group-border-radius);
}
.list-group-numbered {
    list-style-type: none;
    counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section;
}
.list-group-item-action {
    width: 100%;
    color: var(--bs-list-group-action-color);
    text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg);
}
.list-group-item {
    position: relative;
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
    border-top-width: 0;
}
.list-group-item + .list-group-item.active {
    margin-top: calc(-1 * var(--bs-list-group-border-width));
    border-top-width: var(--bs-list-group-border-width);
}
.list-group-horizontal {
    flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
    margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
}
@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}
@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}
@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}
@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}
@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row;
    }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}
.list-group-flush {
    border-radius: 0;
}
.list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
}
.list-group-item-light {
    --bs-list-group-color: var(--bs-light-text-emphasis);
    --bs-list-group-bg: var(--bs-light-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
    --bs-list-group-active-color: var(--bs-light-bg-subtle);
    --bs-list-group-active-bg: var(--bs-light-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}
.list-group-item-primary {
    --bs-list-group-color: var(--bs-primary-text-emphasis);
    --bs-list-group-bg: var(--bs-primary-bg-subtle);
    --bs-list-group-border-color: var(--bs-primary-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
    --bs-list-group-active-color: var(--bs-primary-bg-subtle);
    --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}
.list-group-item-secondary {
    --bs-list-group-color: var(--bs-secondary-text-emphasis);
    --bs-list-group-bg: var(--bs-secondary-bg-subtle);
    --bs-list-group-border-color: var(--bs-secondary-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
    --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
    --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}
.list-group-item-success {
    --bs-list-group-color: var(--bs-success-text-emphasis);
    --bs-list-group-bg: var(--bs-success-bg-subtle);
    --bs-list-group-border-color: var(--bs-success-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
    --bs-list-group-active-color: var(--bs-success-bg-subtle);
    --bs-list-group-active-bg: var(--bs-success-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}
.list-group-item-info {
    --bs-list-group-color: var(--bs-info-text-emphasis);
    --bs-list-group-bg: var(--bs-info-bg-subtle);
    --bs-list-group-border-color: var(--bs-info-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
    --bs-list-group-active-color: var(--bs-info-bg-subtle);
    --bs-list-group-active-bg: var(--bs-info-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}
.list-group-item-warning {
    --bs-list-group-color: var(--bs-warning-text-emphasis);
    --bs-list-group-bg: var(--bs-warning-bg-subtle);
    --bs-list-group-border-color: var(--bs-warning-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
    --bs-list-group-active-color: var(--bs-warning-bg-subtle);
    --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}
.list-group-item-danger {
    --bs-list-group-color: var(--bs-danger-text-emphasis);
    --bs-list-group-bg: var(--bs-danger-bg-subtle);
    --bs-list-group-border-color: var(--bs-danger-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
    --bs-list-group-active-color: var(--bs-danger-bg-subtle);
    --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}
.list-group-item-dark {
    --bs-list-group-color: var(--bs-dark-text-emphasis);
    --bs-list-group-bg: var(--bs-dark-bg-subtle);
    --bs-list-group-border-color: var(--bs-dark-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
    --bs-list-group-active-color: var(--bs-dark-bg-subtle);
    --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}
.btn-close {
    --bs-btn-close-color: #000000;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: none;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0.25em 0.25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/.75rem auto no-repeat;
    border: 0;
    border-radius: 0.475rem;
    opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close.disabled,
.btn-close:disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity);
}
.btn-close-white {
    filter: var(--bs-btn-close-white-filter);
}
[data-bs-theme="dark"] .btn-close {
    filter: var(--bs-btn-close-white-filter);
}
.toast {
    --bs-toast-zindex: 1090;
    --bs-toast-padding-x: 0.75rem;
    --bs-toast-padding-y: 0.5rem;
    --bs-toast-spacing: 1.5rem;
    --bs-toast-max-width: 350px;
    --bs-toast-font-size: 0.875rem;
    --bs-toast-color: var(--bs-gray-700);
    --bs-toast-bg: var(--bs-body-bg);
    --bs-toast-border-width: var(--bs-border-width);
    --bs-toast-border-color: transparent;
    --bs-toast-border-radius: var(--bs-border-radius);
    --bs-toast-box-shadow: var(--bs-box-shadow);
    --bs-toast-header-color: var(--bs-gray-700);
    --bs-toast-header-bg: var(--bs-body-bg);
    --bs-toast-header-border-color: var(--bs-border-color);
    width: var(--bs-toast-max-width);
    max-width: 100%;
    font-size: var(--bs-toast-font-size);
    color: var(--bs-toast-color);
    pointer-events: auto;
    background-color: var(--bs-toast-bg);
    background-clip: padding-box;
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    box-shadow: var(--bs-toast-box-shadow);
    border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
    opacity: 0;
}
.toast:not(.show) {
    display: none;
}
.toast-container {
    --bs-toast-zindex: 1090;
    position: absolute;
    z-index: var(--bs-toast-zindex);
    width: max-content;
    max-width: 100%;
    pointer-events: none;
}
.toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing);
}
.toast-header {
    display: flex;
    align-items: center;
    padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
    color: var(--bs-toast-header-color);
    background-color: var(--bs-toast-header-bg);
    background-clip: padding-box;
    border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
    border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
    border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
    margin-right: calc(-0.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x);
}
.toast-body {
    padding: var(--bs-toast-padding-x);
    word-wrap: break-word;
}
.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1.75rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-bg: var(--bs-body-bg);
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 0;
    --bs-modal-border-radius: 0.475rem;
    --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    --bs-modal-inner-border-radius: 0.475rem;
    --bs-modal-header-padding-x: 1.75rem;
    --bs-modal-header-padding-y: 1.75rem;
    --bs-modal-header-padding: 1.75rem 1.75rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 1px;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}
.modal.show .modal-dialog {
    transform: none;
}
.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}
.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    box-shadow: var(--bs-modal-box-shadow);
    outline: 0;
}
.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000000;
    --bs-backdrop-opacity: 0.4;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity);
}
.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}
.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
}
.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

@media (max-width: 991.98px) {
    .landing-header {
        height: 70px;
    }
}

.position-static {
    position: static !important;
}
.position-relative {
    position: relative !important;
}


i.bi,
i[class*=" fa-"],
i[class*=" fonticon-"],
i[class*=" la-"],
i[class^="fa-"],
i[class^="fonticon-"],
i[class^="la-"] {
    line-height: 1;
    font-size: 1rem;
    color: var(--bs-text-muted);
}
a {
    transition: color 0.2s ease;
}
a:hover {
    transition: color 0.2s ease;
}
.opacity-active-0.active {
    opacity: 0 !important;
}

.text-singin{
    font-size: 22px;
}

.signup-mobile{
    display: none;
}

.Notfoundheader{
    font-family: 'Hind', sans-serif;
    font-weight: 400;
    font-size: 28px;
    Line-height: 48px;
    color: #111936;
}

.NotfoundText{
    position: absolute;
    top: 37%;
    left: 64%;
}

.NotfoundSubText{
    font-size: 14px;
}

.homepageButton{
    background-color: #111936 !important;
    color: white !important;
}

.homepageButton:hover .btn-primary:hover {
    background-color: #111936 !important; /* Change the background color on hover */
}

.siginButton{
    width: 240px
}

.mobileviewLogo{
    display: none;
}

.blue-border {
    border-bottom: 2px solid blue; /* Blue border style */
  }
  
  .error-message {
    color: #2D0DEF; /* Red color for the error message */
    display: none; /* Initially hide the error message */
    font-size: 14px;
  }
  

@media (max-width: 991.98px) {
    .signin-mobilepage {
        padding: 20px;
    }

    .signin-mobilepage .signinheader{
        text-align: center !important;
        font-size: 22px;
        font-weight: 400;
    }

     .signinsubheader{
        text-align: center !important;
        font-size: 14px !important;
        margin-top: -25px;
    }

    .signin-mobilepage .signup-mobile{
        text-align: center !important;
        display: block;
    }

    .siginButton{
        width: 240px;
        text-align: center !important;
        justify-content: center;
        align-items: center;
        margin-left: 100px;
    }

    .mobileviewLogo{
        display: block;
        margin-bottom: 50px;
    }

    .mobilelogoImage{
        margin-bottom: 50px;
    }
    
}



body[data-kt-sticky-landing-header="on"] {
    padding-top: 100px;
}
@media (max-width: 991.98px) {
    .landing-header {
        height: 70px;
    }
    .landing-header .landing-menu-wrapper {
        position: relative;
        overflow: auto;
    }
    [data-kt-sticky-landing-header="on"] .landing-header {
        height: 70px;
    }
    .landing-header .menu .menu-link.active {
        color: #78e3ef;
        background-color: rgba(239, 242, 245, 0.4);
    }
    body[data-kt-sticky-landing-header="on"] {
        padding-top: 70px;
    }
}

[data-bs-theme="dark"] .btn.btn-secondary {
    background-color: #1a1a1a;
}
[data-bs-theme="dark"] .btn.btn-secondary .svg-icon,
[data-bs-theme="dark"] .btn.btn-secondary i {
    color: #878794;
}
[data-bs-theme="dark"] .btn.btn-secondary.dropdown-toggle:after {
    color: #878794;
}
.btn-check:active + [data-bs-theme="dark"] .btn.btn-secondary,
.btn-check:checked + [data-bs-theme="dark"] .btn.btn-secondary,
.show > [data-bs-theme="dark"] .btn.btn-secondary,
[data-bs-theme="dark"] .btn.btn-secondary.active,
[data-bs-theme="dark"] .btn.btn-secondary.show,
[data-bs-theme="dark"] .btn.btn-secondary:active:not(.btn-active),
[data-bs-theme="dark"] .btn.btn-secondary:focus:not(.btn-active),
[data-bs-theme="dark"] .btn.btn-secondary:hover:not(.btn-active) {
    background-color: #1a1a1a !important;
}

html {
    font-family: 'Basier Circle', sans-serif;
    text-size-adjust: 100%;
}
body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px !important;
    font-weight: 400;
    font-family: 'Basier Circle', sans-serif;
}


.app-aside {
    transition: none;
    background-color: var(--bs-app-aside-base-bg-color);
    box-shadow: var(--bs-app-aside-base-box-shadow);
}

/* Style for the link container */
.link-container {
    display: flex; /* Display the links in a row */
    justify-content: space-between; /* Distribute space between the links */
    margin-bottom: 10px; /* Add some spacing at the bottom */
  }
  
  /* Style for the links */
  .link-container a {
    padding: 10px; /* Add padding around each link */
    color: #0074E1; /* Text color */
    text-decoration: none; /* Remove underline from links */
    border-radius: 5px; /* Rounded corners for links */
    font-weight: bold; /* Make the text bold */
  }

  .text-active{
    color: #111936 !important;

  }

  /* Style for the first icon */
.first-icon {
    font-size: 2.4rem !important; /* Increase the icon size */
    color: #111936 !important; /* Change the color of the first icon */
  }

  .list-icon{
    font-size: 1.4rem !important; /* Increase the icon size */
    /* color: #111936 !important; Change the color of the first icon */
  }

   
  
  
  /* Add spacing between links */
  .link-container a + a {
    margin-left: 10px; /* Add 10px spacing to the left of each link except the first one */
  }
  




