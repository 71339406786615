/* Header.css */
.myheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fcfcfc;
    padding: 10px 20px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #333; /* Change the color to your preference */
  }
  
  .icon {
    font-size: 24px;
    color: #333; /* Change the color to your preference */
    cursor: pointer;
  }

  .close-icon{
    font-size: 16px !important;
    color: #6c757d;
  }
  