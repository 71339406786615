.app-footer {
    transition: left 0.3s ease, right 0.3s ease;
    display: flex;
    align-items: center;
    background-color: var(--bs-app-footer-bg-color);
    border-top: var(--bs-app-footer-border-top);
}
@media (min-width: 992px) {
    :root {
        --bs-app-footer-height: 60px;
    }
    .app-footer {
        height: var(--bs-app-footer-height);
    }
    [data-kt-app-footer-fixed="true"] .app-footer {
        z-index: 100;
        box-shadow: var(--bs-app-footer-box-shadow);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}


@media (max-width: 991.98px) {
    body {
        --bs-app-footer-height: auto;
    }
    .app-footer {
        height: var(--bs-app-footer-height);
    }
    [data-kt-app-footer-fixed-mobile="true"] .app-footer {
        z-index: 100;
        box-shadow: var(--bs-app-footer-box-shadow);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
}